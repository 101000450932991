import React from 'react';
import { Link } from 'react-router-dom';
import { FaPaperPlane } from 'react-icons/fa';
import { FaMapMarkerAlt, FaEnvelopeOpen,FaFacebookF } from 'react-icons/fa';
import { AiOutlineGoogle } from 'react-icons/ai';
import { BsTwitter, BsInstagram } from 'react-icons/bs';

const Footer = () => {
   return (
      <>
         <footer className="theme-dark-bg">
            <div className="tp-footer-subscribe-area-two position-relative pt-100">
               <div className="container">
                  <div className="tp-footer-subscribe-bg-two theme-yellow-bg pt-30 pb-20 z-index pl-60 pr-60">
                     <div className="row align-items-center">
                        <div className="col-xl-5 col-lg-4">
                           <div className="tp-footer-subscribe">
                              <h3 className="tp-footer-subscribe-title">Subscribe To Newsletters</h3>
                           </div>
                        </div>
                        <div className="col-xl-7 col-lg-8">
                           <div className="tp-footer-subscribe-form">
                              <form action="#" className="p-0">
                                 <div className="tp-footer-subscribe-form-field mb-10">
                                    <input type="text" placeholder="Email Address"/>
                                       <i ><FaPaperPlane/></i>
                                 </div>
                                 <div className="tp-footer-subscribe-form-btn mb-10">
                                    <button type="submit" className="theme-btn text-white"><i className="flaticon-enter"></i> Subscribe</button>
                                 </div>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="tp-footer-area-two pt-80 pb-50">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-3 col-sm-6">
                        <div className="tp-footer-widget footer-col-1 mb-30 wow fadeInUp" data-wow-delay=".3s">
                           <div className="tp-footer-info">
                              <div className="tp-footer-info-logo mb-35">
                                 <Link to="/"><img src="assets/img/logo/topuplogo_wh.png" className="img-fluid" alt="img not found"/></Link>
                              </div>
                              <h4 className="mb-15"><a href="tel:02(850)2560-3">+1 929 290 3178</a></h4>
                              <h6 className="mb-15"> <i > <FaEnvelopeOpen/> </i><a href="mailto:topupcleaners@gmail.com"></a></h6>
                              <h6 className="mb-20"> <i> <FaMapMarkerAlt /> </i> Manhattan, <br/> Brooklyn, <br/> Queens, <br/> The Bronx, <br/> Mt Vernon, <br/> New Rochelle, <br/> Yonkers, <br/> White Plains, </h6>
                              <div className="tp-footer-info-social">
                                 <a href="https://www.facebook.com/profile.php?id=100070058662642&mibextid=LQQJ4d"><i><FaFacebookF className='icon' /> </i></a>
                                 <a href="/"><i><BsTwitter className='icon' /> </i></a>
                                 <a href="https://instagram.com/topupcleaningservice?igshid=YmMyMTA2M2Y="><i><BsInstagram className='icon' /> </i></a>
                                 <a href="/"><i><AiOutlineGoogle className='icon' /> </i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-3 col-sm-6">
                        <div className="tp-footer-widget footer-col-2 mb-30 wow fadeInUp" data-wow-delay=".6s">
                           <h4 className="tp-footer-widget-title mb-35">Our Services</h4>
                           <ul>
                              <li><a href="#">Home Cleaning</a></li>
                              <li><a href="#">AirBnB</a></li>
                              <li><a href="#">Green Cleaning</a></li>
                              <li><a href="#">Office Cleaning</a></li>
                              <li><a href="#">Post Contruction</a></li>
                              <li><a href="#">Moving In/Moving Out</a></li>
                           </ul>
                        </div>
                     </div>
                     <div className="col-lg-3 col-sm-6">
                        <div className="tp-footer-widget footer-col-3 mb-30 wow fadeInUp" data-wow-delay=".9s">
                           <h4 className="tp-footer-widget-title mb-35">Working Time</h4>
                           <div className="tp-footer-news">
                              <div className="tp-footer-news-single mb-15">
                                 <h6>Monday-Friday</h6>
                                 <span>8:00 am-6:00pm</span>
                              </div>
                              <div className="tp-footer-news-single">
                                 <h6>Saturday</h6>
                                 <span>9:00 am-5:00pm</span>
                              </div>
                           </div>
                        </div>
                     </div>

                     {/* Recent news */}
                     <div className="col-lg-3 col-sm-6">
                        <div className="tp-footer-widget footer-col-3 mb-30 wow fadeInUp" data-wow-delay=".9s">
                           <h4 className="tp-footer-widget-title mb-35">Recent News</h4>
                           <div className="tp-footer-news">
                              <div className="tp-footer-news-single mb-15">
                                 <h6><Link to="/blogDetails">Assisting the Bronx initiate an effective cleaning teachnique</Link></h6>
                                 <span>Jun 02, 2022</span>
                              </div>
                              <div className="tp-footer-news-single">
                                 <h6><Link to="/blogDetails">Five effective ways of ensuring a safe home assitance.</Link></h6>
                                 <span>Jun 02, 2021</span>
                              </div>
                           </div>
                        </div>
                     </div>
                     {/* <div className="col-lg-3 col-sm-6">
                        <div className="tp-footer-widget footer-col-4 mb-30 wow fadeInUp" data-wow-delay="1.2s">
                           <h4 className="tp-footer-widget-title mb-40">Instagram</h4>
                           <div className="tp-footer-insta">
                              <a href="/">
                                 <img src="assets/img/footer/footer-insta-1.jpg" className="img-fluid" alt="img not found"/>
                                 </a>
                              <a href="/">
                                 <img src="assets/img/footer/footer-insta-2.jpg" className="img-fluid" alt="img not found"/>
                                 </a>
                              <a href="/">
                                 <img src="assets/img/footer/footer-insta-3.jpg" className="img-fluid" alt="img not found"/>
                                 </a>
                              <a href="/">
                                 <img src="assets/img/footer/footer-insta-4.jpg" className="img-fluid" alt="img not found"/>
                                 </a>
                              <a href="/">
                                 <img src="assets/img/footer/footer-insta-5.jpg" className="img-fluid" alt="img not found"/>
                                 </a>
                              <a href="/">
                                 <img src="assets/img/footer/footer-insta-6.jpg" className="img-fluid" alt="img not found"/>
                                 </a>
                           </div>
                        </div>
                     </div> */}
                  </div>
               </div>
            </div>
            <div className="tp-copyright-area-two bg-green-light z-index pt-30 pb-30">
               <div className="container">
                  <div className="row">
                     <div className="col-12">
                        <div className="tp-copyright tp-copyright-two text-center">
                           <p className="m-0">Copyright ©2022 <span>printz work</span>. All Rights Reserved Copyright</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

export default Footer;