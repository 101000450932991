import React from 'react';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';

const AboutBreadcrumb = () => {
   return (
      <>
         <Breadcrumb title="About" subTitle="TopUp"></Breadcrumb>
      </>
   );
};

export default AboutBreadcrumb;