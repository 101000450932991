import React,{useState} from 'react';
import { Link } from 'react-router-dom';

import Button from "react-bootstrap/Button"
import MyVerticallyCenteredModal from "./Modal"

const ServicePageArea = ({service_image_num,ser_icon_img,ser_title,colorClass,details}) => {
   // const additional =()=>{
   //    return (<p className="mb-30">Buscipit tincidunt duis antino gravidia nam tellusy nascetur neque vulpuits aenean is scelerisque ultrces muscle mass and matter order commo</p>)
   // }
   const [show, setShow] = useState(false);
   const [detailing,setDetailing] = useState(details)

   //const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   // MOdal
// const mode = ()=>{
//    return( <Modal.Dialog>
//       <Modal.Header closeButton>
//         <Modal.Title>Modal title</Modal.Title>
//       </Modal.Header>

//       <Modal.Body>
//         <p>Modal body text goes here.</p>
//       </Modal.Body>

//       <Modal.Footer>
//         <Button variant="secondary">Close</Button>
//         <Button variant="primary">Save changes</Button>
//       </Modal.Footer>
//     </Modal.Dialog>)

// }

//console.log(details)
   return (
     

      <>
         <div className="col-lg-6">
            <div className="tp-service-three tp-service-four mb-30 wow fadeInUp" data-wow-delay="1.2s">
               <div className="tp-service-three-img">
                  <img src={`assets/img/service/service-${service_image_num}.jpg`} className="img-fluid" alt="img-not-found"/>
                     <div className="tp-service-three-img-icon">
                        <i className={`flaticon-${ser_icon_img}`}></i>
                     </div>
                     <div className="tp-service-three-img-overlay">
                        <div className="tp-service-three-img-overlay-icon">
                          <i className={`flaticon-${ser_icon_img}`}></i>
                        </div>
                     </div>
               </div>
               <div className="tp-service-three-text fix">
                  <h4 className={ colorClass ? "home_three_title tp-service-three-title mb-20 heading-color-black-with-hover" : "tp-service-three-title mb-20 heading-color-black-with-hover"}>
                  {ser_title}</h4>
                 
               {/* <div className="tp-service-three-text-btn">
                  <Link to="/servicesDetails" className="yellow-btn service__btn">
                     <i className="flaticon-enter"></i> Learn More</Link>
               </div> */}

         
{/* start */}
<Button variant="tp-service-three-text-btn" onClick={handleShow}>
       <div className="yellow-btn service__btn" >Read More</div>
        
      </Button>
      
      <MyVerticallyCenteredModal
        show={show}
        onHide={() => setShow(false)}
        modalDescription ={detailing}
      />

     

{/* ends */}
               </div>
            </div>
         </div>
      </>
   );
};

export default ServicePageArea;