import React from 'react';
import HomeSinglePrice from '../../../../components/HomeSinglePrice/HomeSinglePrice';
import SpecialPricing from './SpecialPricing';
import  "./styles.css";
import Extra from "./Extra";
const PricingArea = () => {
const priceListRes = [
   {
   iconName: "mop",
   title:"Studio",
   price:".",
   
   one:"Clean & mop all floor surfaces",
   two:"Dust and clean all accessible surfaces",
   three:"Vacuum floor/ carpet",
   four:"Wash and sanitize the toilet, shower, bathtub and sink",
   five: "Take out garbage and recycling"   
},
{
   iconName: "cleaning-1 ",
   title:"One Bedroom",
   price:"160",
   
      one:"Clean & mop all floor surfaces",
      two:"Dust and clean all accessible surfaces",
      three:"Vacuum floor/ carpet",
      four:"Wash and sanitize the toilet, shower, bathtub and sink",
      five: "Take out garbage and recycling" 
},
{
   iconName: "vacuum-cleaner-1",
   title:"Two Bedroom",
   price:"190",
   
   one:"Clean & mop all floor surfaces",
   two:"Dust and clean all accessible surfaces",
   three:"Vacuum floor/ carpet",
   four:"Wash and sanitize the toilet, shower, bathtub and sink",
   five: "Take out garbage and recycling"  
},
// {
//    iconName: "vacuum-cleaner-1",
//    title:"Three Bedroom",
//    price:"360",
   
//       one:"One Time $360",
//       two:"Weekly $166.57",
//       three:"Bi-Weekly $173.97",
//       four:"Monthly $175.83"  
// },
]
const priceListCom = [
   {
   iconName: "mop",
   title:"Office Cleaning",
   price:"120",
   
      one:"One Time",
      two:"Weekly ",
      three:"Bi-Weekly ",
      four:"Monthly "  
},
{
   iconName: "cleaning-1 ",
   title:"Post Construction",
   price:"120",
   
      one:"One Time",
      two:"Weekly  ",
      three:"Bi-Weekly ",
      four:"Monthly "  
},
{
   iconName: "vacuum-cleaner-1",
   title:"AirBnB Cleaning",
   price:"157.32",
   
      one:"One Time ",
      two:"Weekly ",
      three:"Bi-Weekly ",
      four:"Monthly "  
},
]







   return (
      <>
         <section className="tp-pricing-area pt-120 pb-90">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-md-8">
                     <div className="section-title-wrapper mb-55 wow fadeInUp" data-wow-delay=".2s">
                        <h5 className="tp-section-subtitle common-yellow-shape section__sm__title mb-20">Our Affordable Package</h5>
                        <h2 className="tp-section-title">Special Pricing Package </h2>
                     </div>
                  </div>
                  <div className="col-md-4">
                     <nav className="text-start text-md-end wow fadeInUp" data-wow-delay=".4s">
                        <div className="nav tp-pricing-tabs" id="nav-tab" role="tablist">
                           <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Residential</button>
                           <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Commercial</button>
                        </div>
                     </nav>
                     
                  </div>
                  <div></div>
                     {/* <div className='mb-30 center justify-content-md-center' aria-labelledby="nav-profile-tab" >
                     <SpecialPricing price={70}/>
                  </div>
                  <div className='mb-30 center justify-content-md-center' aria-labelledby="nav-profile-tab" >
                     <SpecialPricing price={80}/>
                  </div> */}
                  <div className='pd-2'></div>
                 
               </div>
               <div className="row">
                  <div className="col-12 mb-60">
                     <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        <div className='mb-30 center justify-content-md-center' aria-labelledby="nav-profile-tab" >
                     <SpecialPricing price={80} show={true}/>
                  </div>
                           <div className="row">

                             {
                              priceListRes.map((price)=><HomeSinglePrice  icon_name={price.iconName} title={price.title} price={price.price} one={price.one} two={price.two} three={price.three} four={price.four} flat={true} />)
                             }
                              

{/* <HomeSinglePrice icon_name="cleaning-1" title="One Bedroom" price="129.56" />

<HomeSinglePrice icon_name="vacuum-cleaner-1" title="Two Bedroom" price="157.32" /> */}

                           </div>
                        </div>
                        <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" >
                        <div className='mb-30 center justify-content-md-center' aria-labelledby="nav-profile-tab" >
                     <SpecialPricing price={80}/>
                  </div>
                  <div className='pd-2'></div>

                           <div className="row">
                              {priceListCom.map((price)=><HomeSinglePrice title={price.title} icon_name={price.iconName} one={price.one} two={price.two} three={price.three} four={price.four}/>)}
                              {/* <HomeSinglePrice icon_name="mop" title="Residential" price="95.00"  />

                              <HomeSinglePrice icon_name="cleaning-1" title="Buildings" price="120.00" />

                              <HomeSinglePrice icon_name="vacuum-cleaner-1" title="Commercial" price="145.00" /> */}

                           </div>
                        </div>
                     </div>
                  </div>
                        <Extra />
               </div>
            </div>
         </section>
      </>
   );
};

export default PricingArea;