import React from "react";
import { Link } from "react-router-dom";

const AboutArea = () => {
  return (
    <>
      <section className="tp-about-area position-relative pt-120 pb-90 fix">
        <div className="tp-about-shape">
          <img
            src="assets/img/about/about-shape-1.jpg"
            className="img-fluid"
            alt="img not found"
          />
        </div>
        <div className="container">
          <div className="row justify-content-xl-between justify-content-md-center">
            <div className="col-xl-5 col-12">
              <div
                className="tp-about-img z-index wow fadeInUp"
                data-wow-delay=".3s"
              >
                <img
                  src="assets/img/about/bana.jpg"
                  alt="img not found"
                />
              </div>
            </div>
            <div className="col-xl-6 col-md-10">
              <div
                className="tp-about-text z-index wow fadeInUp"
                data-wow-delay=".6s"
              >
                <div className="section-title-wrapper mb-30">
                  <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20">
                    About our Company
                  </h5>
                  <h2 className="tp-section-title">
                    Cleaning 
                    Excellence
                    Guaranteed
                  </h2>
                </div>
                <p className="mb-40">
                  Top-Up Cleaning Service, LLC has been providing high-quality
                  and dependable cleaning and maintenance services tailored to
                  meet our clients unique needs. Our goal is to leave a positive
                  and lasting impressions that you would be proud to be
                  associated with our services. Our team is made up of well
                  trained and seasoned cleaning professionals, fully dedicated
                  and committed to meet and surpass your expectations. Top-Up
                  Cleaning service treats your space and property with the
                  respect and courtesy it deserves. Top-Up Cleaning also
                  provides green and environmentally sustainable cleaning
                  program customized to meet the health and well being needs of
                  our clients family and pets.
                </p>

                <h2 className="tp-section-title">
                Our Mission
                  </h2>
                <p className="mb-40">
                
At Top-Up cleaning, we’re committed to providing the highest quality of cleaning available by
exceeding the expectations of our clients.

                </p>

                <h2 className="tp-section-title">
                 Our Vision
                  </h2>
                <p className="mb-40">
                
To become the most trusted, respected, and reliable cleaning company.
                </p>

                
                {/* <div className="row mb-10">
                  <div className="col-sm-6">
                    <div className="tp-about-service mb-30">
                      <div className="tp-about-service-icon yellow-circle-shape mb-15">
                        <i className="flaticon-snowfall"></i>
                      </div>
                      <div className="tp-about-service-text">
                        <h4 className="tp-about-service-text-title mb-15 hover-theme-color">
                          <Link to="/services">
                            Residential Cleaning Services
                          </Link>
                        </h4>
                        <p>
                          Top-Up Cleaning Service, LLC has been providing
                          high-quality and dependable cleaning and maintenance
                          services tailored to meet our clients unique needs.
                          Our goal is to leave a positive and lasting
                          impressions that you would be proud to be associated
                          with our services. Our team is made up of well trained
                          and seasoned cleaning professionals, fully dedicated
                          and committed to meet and surpass your expectations.
                          Top-Up Cleaning service treats your space and property
                          with the respect and courtesy it deserves. Top-Up
                          Cleaning also provides green and environmentally
                          sustainable cleaning program customized to meet the
                          health and well being needs of our clients family and
                          pets.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="tp-about-service mb-30">
                      <div className="tp-about-service-icon yellow-circle-shape mb-15">
                        <i className="flaticon-business-and-trade"></i>
                      </div>
                      <div className="tp-about-service-text">
                        <h4 className="tp-about-service-text-title mb-15 hover-theme-color">
                          <Link to="/services">
                            Commercial Cleaning Services
                          </Link>
                        </h4>
                        <p>
                          Top up Cleaning provides green and sustainable
                          cleaning program customized to meet the health and
                          well-being needs of our clients businesesses and
                          places of work
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="row">
                           <div className="col-sm-6">
                              <div className="tp-about-author mb-30">
                                 <div className="tp-about-author-img">
                                    <img src="assets/img/about/about-author.png" className="img-fluid" alt="img not found"/>
                                 </div>
                                 <div className="tp-about-author-text">
                                    <h4 className="tp-about-author-text-title">Daniel Martyn</h4>
                                    <span>CEO - Fetoxe</span>
                                 </div>
                              </div>
                           </div>
                           <div className="col-sm-6">
                              <div className="tp-about-number mb-30">
                                 <div className="tp-about-number-icon">
                                    <i className="flaticon-phone-call-1"></i>
                                 </div>
                                 <div className="tp-about-number-text">
                                    <span>Free Consutacy</span>
                                    <a href="tel:02(552)6620808">02 (552) 662 0808</a>
                                 </div>
                              </div>
                           </div>
                        </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutArea;
