import React from "react";
import ServicePageArea from "../../../../components/ServiceArea/ServicePageArea";
import ServicePageAreaCom from "../../../../components/ServiceArea/ServicePageAreaCom";
import AirbnbServicePageArea from "../../../../components/ServiceArea/AirServicePage";

const ServicesArea = () => {
  //const details={1:"Office cleaning",2:"Medical office cleaning "}
  return (

    <>
      <section className="tp-service-area-three pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="section-title-wrapper text-center mb-55 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black">
                  Our Best Services
                </h5>
                <h2 className="tp-section-title heading-color-black">
                  Quality Cleaning Making <br />
                  you Much Happy
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <ServicePageArea
              service_image_num="11"
              ser_icon_img="house-cleaning"
              details={{
                title: "Home Cleaning",
               
                showResidential: "yesdf",
              }}
              ser_title="Home Cleaning"
            ></ServicePageArea>

            <ServicePageArea
              service_image_num="05"
              ser_icon_img="cleaning"
              details={{
                title: "Green Cleaning",
                description: "If you are concerned about the toxins and noxious odors that typical cleaning products leave behind, Top-Up cleaning service is the green cleaning company for you! Green cleaning is the process of maintaining a sanitary and healthy environment without causing harm to its inhabitants. We provides green and environmentally sustainable cleaning program customized to meet the health and well being needs of our clients family and pets.Top-Up Cleaning Service are professional, friendly and committed to providing a consistent cleaning experience that you can trust. Each cleaning is tailored to your specific needs and follows professional cleaning standards",
                showResidential: false
              }}
              ser_title="Green Cleaning"
            ></ServicePageArea>
<AirbnbServicePageArea
              service_image_num="03"
              ser_icon_img="pot"
              details={{
                title: "AirBnB",
                description: "Keeping your AirBnB rental property as clean as possible is time consuming and challenging, but it is also needed. Your guests expect to set foot in a spotless home away from home when they arrive, and their first impression can set the tone for their entire experience in your rental home. Your ability to meet or exceed their expectations is important in order to earn repeat business and to get great reviews.  Let us do the job and you will get more great reviews. Our dedicated staffs are true professionals and will make sure your home is transformed into a spotless place for your rental guests.   You can expect the following cleaning tasks to be done and many more: ",
                showResidential: false
              }}
              ser_title="AirBnB"
            ></AirbnbServicePageArea>

 <ServicePageArea
              service_image_num="01"
              ser_icon_img="cleaning"
              details={{
                title: "Office Cleaning",
                description: "One of the most important parts of having a well-run business is making sure it’s clean for customers and employees.Your offices cleanliness reflects how well it operates. One way to make sure your office is as clean and functional as possible is to call “Top-Up Cleaning Service”. Our mission is to make your clean office our job so your employees can focus on theirs!",
                showResidential: false
              }}
              ser_title="Office Cleaning"
            ></ServicePageArea>




            

            <ServicePageArea
              service_image_num="12"
              ser_icon_img="window"
              details={{
                title: "Post Construction",
                description: "A big construction job at home or office is not complete without a thorough and professional cleaning. After investing so much time and money into improving your living space, Top-Up Cleaning at your service will make sure your house is clean, smells fresh, ready for your loved ones to enjoy.Book a post construction cleaning service and save time and money. We have friendly,committed and professional cleaners who provides services that you can trust.",
                showResidential: false
              }}
              ser_title="Post Construction"
            ></ServicePageArea>

            <ServicePageArea
              service_image_num="02"
              ser_icon_img="desk"
              details={{
                title: "Moving In/ Moving Out Cleaning",
                description: "Moving can be stressful enough without worrying about having to tidy up after you are finished. We are here to take the stress out of moving. We offer thorough move-in and move-out service that will allow you to focus on what matters most in your new home.Our trained cleaning professionals will leave no corner of your home/apartment untouched.With advanced techniques and process, we will make your space look better again." ,
                showResidential: false
              }}
              ser_title="Moving In/ Moving Out Cleaning"
            ></ServicePageArea>

            {/* <ServicePageArea service_image_num="14" ser_icon_img="vacuum-cleaner" 
               ser_title="Carpet Cleaning"></ServicePageArea> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesArea;
