import { Button,Modal } from "react-bootstrap";
import {GiCheckMark} from "react-icons/gi"


export default function MyVerticallyCenteredModal(props) {
  
  
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.modalDescription.title}
          {/* RESIDENTIAL */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h4>Centered Modal</h4> */}
          
      
{
  props.modalDescription.showResidential && <p>
  Imagine walk into a clean house after a hard days work and not needing to move a finger. We are
 customized to help you achieve your dream of a spotless home. Our team is also equipped with the right
 tools and equipment to make sure we leave your home spotless and a healthier environment to be in.
 We offer weekly, bi-weekly and monthly cleaning packages that are designed to fit your needs and
 budget.
 <br/>
 BELOW ARE SERVICES WHEN WE ARE IN YOUR HOME:
 <br/>
 <div className="pb-30"></div>
 <h4>

 Bedrooms &amp; Living Areas
 </h4>
 
 <GiCheckMark/> Dust and wipe all accessible surfaces
 <br/>
 <GiCheckMark/> Vacuum carpet and rugs
 <br/>
 <GiCheckMark/>  Wipe down all mirrors and glass fixtures
 <br/>
 <GiCheckMark/>  Dust furniture within reach (top, front &amp; underneath)
 <br/>
 <GiCheckMark/>  Empty trash and replace liner
 <br/>
 <GiCheckMark/>  Clean floors (vacuum, sweep, mop)
 <br/>
 <GiCheckMark/>  Remove cobwebs
 <br/>
 <GiCheckMark/>  Wipe doors, handles &amp; light switches
 <br/>
 <GiCheckMark/>  Wipe baseboards
 <br/>
 <GiCheckMark/> Your beds are made &amp; Linens are changed if requested
 <div className="pb-30"></div>
 <h4>Kitchen</h4>
 
 <GiCheckMark/> Clean major appliance exteriors (interior upon request)
 <br/>
 <GiCheckMark/> Wash and sanitize sink
 <br/>
 <GiCheckMark/>  Clean microwave – exterior (interior upon request)
 <br/>
 <GiCheckMark/>  Clean and disinfect counters
 <br/>
 <GiCheckMark/>  Clean table and chairs
 <br/>
 <GiCheckMark/>  Empty trash and replace liner
 <br/>
 <GiCheckMark/>  Clean floors (vacuum, sweep, mop)
 <br/>
 <GiCheckMark/>  Remove cobwebs
 <br/>
 <GiCheckMark/> Wipe doors, handles &amp; light switches
 <br/>
 <GiCheckMark/> Wipe baseboards
 <br/>
 <GiCheckMark/>  Wipe outside cabinets &amp; drawers
 <div className="pb-30"></div>
 
 <h4>Bathrooms</h4>
 
 <GiCheckMark/> Clean &amp; Sanitize toilet, shower, bathtub and sink
 <br/>
 <GiCheckMark/>  Dust and wipe all accessible surfaces
 <br/>
 <GiCheckMark/>  All fixtures are disinfected and cleaned
 <br/>
 <GiCheckMark/>  Empty trash and replace liner
 <br/>
 <GiCheckMark/>  Clean floors (vacuum, sweep, mop)
 <br/>
 <GiCheckMark/>  Wipe outside cabinets and drawers
 <br/>
 <GiCheckMark/> All mirrors are cleaned
 <br/>
 <GiCheckMark/> General dusting in all areas
 </p>
}
<p>{props.modalDescription.description}</p>

{/* this is the code that has to be made within the code snippet we need to have the  */}
          
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  