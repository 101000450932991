import React from "react";
import { GiAbstract013 } from "react-icons/gi";

export default function SpecialPricing({price,show}) {
  return (
    <div className=" mb-3" >
        <div class="row no-gutters">

        {/* col-xl-4 */}
      <div className=" col-md-6 mb-30">
        <div
          className="tp-pricing grey-bg text-center row flipInY "
          data-wow-delay=".2s"
        >
          <div className="tp-pricing-rate">
            <div className="tp-pricing-rate-inner text-center">
             {show ? <>
             {/* <h1 className="tp-pricing-rate-title mb-10"> */}
                  {/* <span>$</span>{price} */}
                {/* </h1><h4 className="tp-pricing-rate-subtitle mb-30">Per hour</h4> */}
                <div className="mb-10"><h3>2 Hour Minimum / Per Cleaner</h3></div>
                </>
              :<h1 className="tp-pricing-rate-title mb-10">
                <span>4 Hour minimum</span>
              </h1>
              }
              
             
            </div>
          </div>
          <div className="tp-pricing-list mb-45">
            <h4 className="tp-pricing-rate-subtitle mb-30"></h4>
          </div>

          <div className="tp-pricing-btn"></div>
        </div>
      </div>

      {/* Discount quote */}
      <div className=" col-md-6 mb-30 bg-success">
        <h1 className="text-white mb-30">We Offer flexible and simple pricing for all types of cleaning.</h1>
        <h4 className="text-warning">Get Discounts for recurring service: </h4>
        <div className="text-warning align-middle">Weekly -15% off / Bi-Weekly -10% off / Monthly -5% off</div>
      </div>
      </div>
    </div>
  );
}
