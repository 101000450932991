import React from 'react';
import Footer from '../../../components/shared/Footer/Footer';
import Navigation from '../../../components/shared/Navigation/Navigation';
import PricingAffordablePackage from '../PricingAffordablePackage/PricingAffordablePackage';
import SpecialPricingPackage from '../SpecialPricingPackage/SpecialPricingPackage';
import PricingBreadcrumb from './PricingBreadcrumb/PricingBreadcrumb';
import PricingArea from "../../Home/Home/PricingArea/PricingArea"

const Pricing = () => {
   return (
      <>
         <Navigation/>
         <PricingBreadcrumb />
         {/* <PricingAffordablePackage />
         <SpecialPricingPackage /> */}
         <PricingArea />
         <Footer/>
      </>
   );
};

export default Pricing;