import { Button,Modal } from "react-bootstrap";
import {GiCheckMark} from "react-icons/gi"


export default function AirbnbModal(props) {
  
  
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.modalDescription.title}
          {/* RESIDENTIAL */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h4>Centered Modal</h4> */}
          
      

   <p>
  Keeping your AirBnB rental property as clean as possible is time consuming and challenging, but it is also
needed. Your guests expect to set foot in a spotless home away from home when they arrive, and their
first impression can set the tone for their entire experience in your rental home. Your ability to meet or
exceed their expectations is important in order to earn repeat business and to get great reviews.
Let us do the job and you will get more great reviews. Our dedicated staffs are true professionals and
will make sure your home is transformed into a spotless place for your rental guests.
You can expect the following cleaning tasks to be done and many more:
 

<div className="pb-30"></div>
 
 <h4>

 Living Areas &amp; Bedrooms:
 </h4>
 
 <GiCheckMark/> All of your carpeting and rugs are vacuumed
 <br/>
 <GiCheckMark/> Vacuum, mop and dry all hard floor surfaces
 <br/>
 <GiCheckMark/>  Flat surface areas are dusted
 <GiCheckMark/>  We tidy your room appearance
 <br/>
 <GiCheckMark/>  Your beds are made
 <br/>
 <GiCheckMark/>  Cobwebs are removed
 <br/>
 <GiCheckMark/>  Dusting is done in all areas

 <div className="pb-30"></div>
 <h4>

 Kitchen:
</h4>


 
 <GiCheckMark/>  Your kitchen sinks are scrubbed, disinfected and shined
 <br/>
 <GiCheckMark/>  All countertop appliances are cleaned
 <br/>
 <GiCheckMark/> Your refrigerator exterior is wiped down
 
 
 
 <GiCheckMark/> The outside of your cabinets and doors are wiped down
 <br/>
 <GiCheckMark/> The inside and outside of your microwave is cleaned and disinfected
 <br/>
 <GiCheckMark/>  Tables and chairs are cleaned
 <br/>
 <GiCheckMark/>  All cobwebs are removed
 <br/>
 <GiCheckMark/>  Trash is emptied
 <div className="pb-30"></div>
<h4>
Bathrooms:
</h4>
 
 <GiCheckMark/>  Your bathtubs and tile walls are cleaned and disinfected
 <br/>
 <GiCheckMark/>  Shower, shower doors and walls are cleaned and disinfected
 <br/>
 <GiCheckMark/>  The sinks and countertops are cleaned and disinfected
 <br/>
 <GiCheckMark/> Hard floors are mopped and dried
 <br/>
 <GiCheckMark/> Wipe baseboards
 <br/>
 <GiCheckMark/>  Your toilet is clean and disinfected
 <br/>
 <GiCheckMark/>  All fixtures are disinfected, cleaned and shined
 <br/>
 <GiCheckMark/>  General dusting in all areas
 <br/>
 <GiCheckMark/>  Trash is emptied
 <div className="pb-30"></div>
 
 
 </p>



{/* this is the code that has to be made within the code snippet we need to have the  */}
          
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  