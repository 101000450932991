import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import "swiper/css";
import "swiper/css/navigation";
// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";
import HomeSingleTestimonial from "../../../../components/HomeSingleTestimonial/HomeSingleTestimonial";
SwiperCore.use([Navigation]);

// SwiperCore.use([Pagination]);

const Testimonial = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  return (
    <>
      <section className="tp-testimonial-area position-relative">
        <div className="container">
          <div className="tp-testimonial-bg white-bg z-index">
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-6">
                <div className="tp-testimonial-img">
                  <img
                    src="assets/img/testimonial/testimonial-img-1.jpg"
                    alt="img bot found"
                  />
                </div>
              </div>
              <div className="col-xl-7 col-lg-6">
                <div className="tp-testimonial ml-70">
                  <div className="section-title-wrapper">
                    <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20">
                      Customer Statement
                    </h5>
                    <h2 className="tp-section-title mb-20">
                      Our Client Feedback
                    </h2>
                  </div>
                  <div className="tp-testimonial-active swiper-container">
                    <Swiper
                      spaceBetween={30}
                      slidesPerView={1}
                      className="swiper-wrapper"
                      autoplay={{ delay: 6000 }}
                      // pagination={{ clickable: true }}
                      // navigation={true}
                      onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                      }}
                      breakpoints={{
                        550: {
                          slidesPerView: 1,
                        },
                        768: {
                          slidesPerView: 1,
                        },
                        1200: {
                          slidesPerView: 1,
                        },
                      }}
                      navigation={{
                        nextEl: ".testimonial-button-next",
                        prevEl: ".testimonial-button-prev",
                      }}
                      // onSlideChange={() => console.log('slide change')}
                      // onSwiper={(swiper) => console.log(swiper)}
                    >
                      <SwiperSlide>
                        <HomeSingleTestimonial
                          image="1"
                          name="Rich Gragory"
                          title="Chief Marketing Manager"
                          testemonial="My entire apartment used to be in a mess. This became a very massive problem for me. Ever since I was introduced to Top Up Cleaning Services, I have felt some sense of relief. I know I can rely on them ,any day , any time"
                        />
                      </SwiperSlide>

                      <SwiperSlide>
                        <HomeSingleTestimonial
                          image="1"
                          name="Evelyn Ocran"
                          title="Kakes n Cream"
                          testemonial="Contact Top-Up Cleaning Services for all your residential and commercial cleaning.They did an excellent job for me when I was moving out of my home and I recommend them for you all.
                          Their prices are moderate and they are very reliable."
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <HomeSingleTestimonial
                        name="Alfred Barcliff"
                        title="Realtor"
                        testemonial="My office looks remarkable week in and week out.Very professional and excellent service. I highly recommend Top-Up Cleaning Service for your cleaning requirements."
                        />
                      </SwiperSlide>

                      <SwiperSlide>
                        <HomeSingleTestimonial
                          image="2"
                          name="The Practical Diva,"
                          title="'Your small Project Specialist for all your Handy[wo]man needs' "
                          testemonial=" Rit and her crew are my go to for after construction clean up. I am a contructor and part of my job includes making 
                          big messes.And I like to offer my client a clean house at the end of their projects and Rit and Top-Up Cleaning make it happen for me. Pontual,
                          friendly, efficient, I highly recommend Top-Up Cleaning.  "
                          website="www.thepracticaldiva.com / 347-363-6692"
                        />
                      </SwiperSlide>

                      {/* <SwiperSlide>
                        <HomeSingleTestimonial
                          image="3"
                          name="Shon Toy"
                          title="Chief Marketing Officer"
                          testemonial=" Customer feedback does more than just ensure that your customers feel
                          heard and valued throughout their journey with your product or
                          service. The foundation for building this rapport with your customers
                          is using feedback software that will capture and analyze customer
                          inputs"
                        />
                      </SwiperSlide> */}
                      {/* Addition */}

                      <div ref={prevRef}>Prev</div>
                      <div ref={nextRef}>Next</div>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>

            <div className="tp-testimonial-slider-arrow">
              <div className="testimonial-button-next slide-next">
                <i>
                  <BsChevronRight />
                </i>
              </div>
              <div className="testimonial-button-prev slide-prev">
                <i>
                  <BsChevronLeft />
                </i>
              </div>
            </div>
          </div>
        </div>
        <div className="tp-testimonial-shape home"></div>
      </section>
    </>
  );
};

export default Testimonial;
