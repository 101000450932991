import React from "react";
import { GiCheckMark,GiCancel } from 'react-icons/gi';
import './extra.css'


export default function SpecialPricing() {
  return (
    <div >
      <div
        className=" grey-bg text-center row flipInY "
        data-wow-delay=".2s"
      >
        <div className="tp-pricing-rate">
          <div className="tp-pricing-rate-inner text-center">
            <h1 className="tp-section-title mb-30">
              Extra Services
            </h1>
            <h4 className="tp-pricing-rate-subtitle mb-20"><GiCheckMark/> Deep cleaning </h4>
            <h4 className="tp-pricing-rate-subtitle mb-20"><GiCheckMark/>  Move in / Move out cleaning </h4>
           
            <h4 className="tp-pricing-rate-subtitle mb-20"><GiCheckMark/> Clean inside The Fridge </h4>
            <h4 className="tp-pricing-rate-subtitle mb-20"><GiCheckMark/> Clean inside The Cabinets</h4>
            <h4 className="tp-pricing-rate-subtitle mb-20"><GiCheckMark/> Clean inside The Oven</h4>
            <h4 className="tp-pricing-rate-subtitle mb-20"><GiCheckMark/> Clean Interior Windows </h4>
            <h4 className="tp-pricing-rate-subtitle mb-20"><GiCheckMark/> Clean Interior walls </h4>
            <h4 className="tp-pricing-rate-subtitle mb-20"><GiCheckMark/> One hour of Organizing </h4>
            
            

          </div>

        
        </div>
       

        
      </div>


      <div
        className="tp-pricing grey-bg text-center row flipInY "
        data-wow-delay=".2s"
      >
        <div className="tp-pricing-rate">
        
<div >
<div className="tp-pricing-rate-inner text-center">
    <div className="mb-70"></div>
            <h2 className="tp-section-title mb-20">
            What We Can’t Clean
            </h2>
            <h4 className="tp-pricing-rate-subtitle mb-20"><GiCancel/>Exterior Windows</h4>
            <h4 className="tp-pricing-rate-subtitle mb-20"><GiCancel/>  Carpet cleaning</h4>
           
            <h4 className="tp-pricing-rate-subtitle mb-20"><GiCancel/> Animal waste</h4>
            <h4 className="tp-pricing-rate-subtitle mb-20"><GiCancel/> Mold removal</h4>
            <h4 className="tp-pricing-rate-subtitle mb-20"><GiCancel/> Industrial Cleaning</h4>
            <h4 className="tp-pricing-rate-subtitle mb-20"><GiCancel/> Lifting of heavy items</h4>
            
            <h4 className="tp-pricing-rate-subtitle mb-20"><GiCancel/> Surfaces well above arms reach</h4>
            
            

          </div>
</div>
        
        </div>
       

        
      </div>

      
        {/* Discount quote */}
  

  {/* What We Can’t Clean
- Exterior Windows
- Carpet cleaning
- Animal waste
- Mold removal
- Industrial Cleaning
- Lifting of heavy items
- Surfaces well above arms reach */}
    </div>
  );
}
