import React,{useEffect,useState} from 'react';
import { GiCheckMark } from 'react-icons/gi';
import { Link } from 'react-router-dom';

const HomeSinglePrice = ({icon_name,title,price,one,two,three,four,flat}) => {
   
  
  // console.log(itera)
  
   //const alDay =[...otherDays];
  // console.log(otherDays)
   // const [days,setDays] = useState([]);
   // useEffect(()=>setDays(...otherDays),[])
   // console.log(days)
   
   return (
      <>
         <div className="col-xl-4 col-md-6 mb-30">
            <div className="tp-pricing grey-bg text-center wow flipInY" data-wow-delay=".2s">
               <div className="tp-pricing-icon mb-40">
                  <div className="tp-pricing-icon-inner">
                     <i className={`flaticon-${icon_name}`}></i>
                  </div>
               </div>
               <div className="tp-pricing-rate">
                  <div className="tp-pricing-rate-inner text-center">
                     <h4 className="tp-pricing-rate-subtitle mb-30">{title}</h4>
                     <div className='mb-45'>

                     {/* {flat && <h2 className="tp-pricing-rate-title "><span>$</span>{price}</h2>} */}
                     {flat && <div><h3>flat rate  </h3><h3>(Under 1,000 sq.ft )</h3></div>
                     
                     }
                     </div>
                  </div>
               </div>
               <div className="tp-pricing-list mb-45">
                  <ul>
                  
                     <li><i><GiCheckMark/> </i>{one}</li>
                     <li><i><GiCheckMark/> </i> {two}</li>
                     <li className='tp-pricing-rate-subtitle'><i ><GiCheckMark/> </i> {three}</li>
                     <li><i><GiCheckMark/> </i> {four}</li>
                     {/* <li><i > <GiCheckMark/> </i> Bathroom Cleaning</li>
                     <li><i > <GiCheckMark/> </i> Floor Cleaning</li>
                     <li><i > <GiCheckMark/> </i> Bedroom Cleaning</li> */}
                  </ul>
               </div>
               <div className="tp-pricing-btn">
                  <Link to="/contact" className="theme-btn text-white"><i className="flaticon-enter"></i> Get Service</Link>
               </div>
            </div>
         </div>
      </>
   );
};

export default HomeSinglePrice;